
.rhplus-md {
  margin: 0.2em;
  height: 1.1em;
}

.rhplus-lg {
  height: 1.33333333em;
  /* line-height: 0.75em; */
  /* vertical-align: -15%; */
}
.rhplus-2x {
  height: 2em;
}
.rhplus-3x {
  height: 3em;
}
.rhplus-4x {
  height: 4em;
}
.rhplus-5x {
  height: 5em;
}
.rhplus-fw {
  width: 1.28571429em;
  text-align: center;
} 
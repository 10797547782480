/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer;
}

.btn, .jr-btn {
  position: relative;
  min-width: 10px !important;
  min-height: 10px !important;
  font-size: $btn-font-size + 2 !important;
  font-weight: 400 !important;
  text-transform: none !important;
  line-height: $btn-line-height !important;
  @include border-radius($border-radius-sm !important);
  cursor: pointer;
}

.btn {
  &:focus,
  &:active {
    outline: 0 none !important;
    @include box-shadow(none !important);
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.jr-btn,
.jr-fab-btn,
.btn {
  margin-bottom: 6px;
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.jr-fab-btn {
  @extend %size-50;
  min-height: 10px !important;
}

.jr-btn {
  padding: $btn-padding !important;
  font-size: $btn-font-size !important;

  & i + span,
  & span + i {
    margin-left: 6px;
  }
}

.jr-btn-group {
  margin: 0 -10px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .jr-btn,
  & .jr-fab-btn {
    margin: 0 10px 18px;
  }
}

.jr-btn-rounded {
  @include border-radius(50px !important);
}

.jr-btn-lg {
  padding: $btn-padding-lg !important;
}

.jr-btn-sm {
  padding: $btn-padding-sm !important;
}

.jr-btn-xs {
  padding: $btn-padding-xs !important;
}

.jr-btn-grid {
  padding: $btn-padding-grid !important;
}

// Fab Button
.jr-btn-fab-lg {
  @extend %size-60;
}

.jr-btn-fab-sm {
  @extend %size-40;
}

.jr-btn-fab-xs {
  @extend %size-30;
}

.jr-btn-fab-lg,
.jr-btn-lg {
  font-size: $btn-font-size-lg !important;
  & i {
    font-size: ($btn-font-size-lg + 2px) !important;
  }
}

.jr-btn-fab-sm,
.jr-btn-sm {
  font-size: $btn-font-size-sm !important;
  & i {
    font-size: $btn-font-size-sm !important;
  }
}

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: $btn-font-size-xs !important;
  & i {
    font-size: $btn-font-size-xs !important;
  }
}

.jr-btn-grid {
  font-size: $btn-font-size-grid !important;
  & i {
    font-size: $btn-font-size-grid !important;
  }
}

.complex-btn-wrapper {
  @include display-flex();
  min-width: 200px;
  width: 100%;
}

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 100% !important;
    height: 100px !important;
  }
  &:hover {
    z-index: 1;
    & .img-btn-overlay {
      opacity: 0.15;
    }

    & .img-marked {
      opacity: 0;
    }

    & .img-title {
      border: 4px solid currentColor;
    }
  }

  .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    @include align-items(center);
    @include justify-content(center);
    color: $white;
  }
  .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%;
  }

  .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $black;
    opacity: 0.4;
    @include transition(all 0.5s $transition-ease);
  }

  .img-title {
    position: relative;
    padding: 16px 32px 14px;
  }
  .img-marked {
    height: 3px;
    width: 18px;
    background: $white;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    @include transition(all 0.5s $transition-ease);
  }
}

.btn-group-mins {
  margin: 0 -5px;

  & .btn,
  & .jr-btn {
    margin: 0 5px 5px;
  }
}

//buttons in label

.jr-btn.jr-btn-label i {
  background-color: rgba($white, 0.2);
  width: $size-40;
  height: 100%;
  line-height: 42px;
}

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.left i + span {
  margin-left: $size-30;
}

.jr-btn.jr-btn-label.right i + span {
  margin-right: $size-30;
  margin-left: 0;
}

.jr-btn.jr-btn-label.jr-btn-lg i {
  line-height: 54px;
}

.jr-btn.jr-btn-label.jr-btn-sm i {
  line-height: 32px;
}

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: $size-30;
}
.jr-link {
  cursor:pointer;
  color: $app-primary;

  &:hover,
  &:focus {
    color: darken($app-primary, 10%);
  }
}
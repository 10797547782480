.react-calendar {
  border: 2px solid #EBEDF4 !important;
  width: 280px !important;
}

.react-calendar__navigation {
  background-color: #EBEDF4;
  border: none;
  margin-bottom: 2px !important;
}

.react-calendar__month-view__days__day {
  margin: 0px !important;
  padding: 8px !important;

  & abbr {
    font-size: 12px !important;
  }
}

.react-calendar__month-view__weekdays {
  margin-bottom: 10px !important;
}

.react-calendar__month-view {
  color: $body-color !important;
  font-weight: 500 !important;
  padding-top: 8px !important;

  & abbr {
    font-size: 15px;
    text-transform: capitalize;
  }
}

.react-calendar__tile {
    color: #8F91A4;
}

.react-calendar__navigation__label {
  font-family: $font-family-base;
  color: $body-color;
}
/*Theme Green Styles*/
$app-primary-green: #46c78f !default;
$secondary-green: #cdd2e3;
$btn-color: #3b4784;

$sidebar-green: rgba($app-primary-green, 0.25) !default;
$sidebar-text-green: #868e96 !default;

$app-head-green: #0095a8;

//-Base-scss
.green .right-arrow {
  color: $app-primary-green;
  &:after {
    color: $app-primary-green;
  }
}

//- Bootstrap file Style
.green {
  .jr-link,
  .jr-link.text-primary {
    color: $app-primary-green;
    &:focus,
    &:hover {
      color: darken($app-primary-green, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-green !important;
  }

  & .page-link {
    color: $app-primary-green;
    &:focus,
    &:hover {
      color: $app-primary-green;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $app-primary-green;
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-green !important;
    color: $white !important;
  }

  & .btn-primary {
    background-color: $app-primary-green;
    border-color: $app-primary-green;
    color: $white;
    font-weight: $font-weight-bold !important;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-green, 5%) !important;
      border-color: darken($app-primary-green, 5%) !important;
      color: $white !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #C8E6C9 !important;
    }

    &.lighten-3 {
      background-color: #A5D6A7 !important;
    }

    &.lighten-2 {
      background-color: #81C784 !important;
    }

    &.lighten-1 {
      background-color: #66BB6A !important;
    }

    &.darken-1 {
      background-color: #43A047 !important;
    }

    &.darken-2 {
      background-color: #388E3C !important;
    }

    &.darken-3 {
      background-color: #2E7D32 !important;
    }

    &.darken-4 {
      background-color: #1B5E20 !important;
    }

    &.accent-1 {
      background-color: #B9F6CA !important;
    }

    &.accent-2 {
      background-color: #69F0AE !important;
    }

    &.accent-3 {
      background-color: #00E676 !important;
    }

    &.accent-4 {
      background-color: #00C853 !important;
    }
  }
}

//Secondary
.green {
  .jr-link.text-secondary {
    color: $secondary-green;
    &:focus,
    &:hover {
      color: darken($secondary-green, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-green !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-green !important;
    color: $white !important;
  }

  & .btn-secondary {
    background-color: $secondary-green;
    border-color: $secondary-green;
    color: $btn-color;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-green, 5%) !important;
      border-color: darken($secondary-green, 5%) !important;
      // color: $white !important;
    }
  }
}

//_header.scss
.green .app-main-header {
  background-color: $app-primary-green !important;

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.green .color-theme-header {
  background-color: $app-primary-green;
}

//_sidebar.scss
.green .side-nav {
  background-color: $white !important;
  color: $sidebar-text-green !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));

  & .user-profile {
    background-color: $white;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: $jr-border;
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-green, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $app-primary-green;
    }
  }
}

.green ul.nav-menu li button,
.green ul.nav-menu li a {
  color: $sidebar-text-green;
}

.green ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-green;
  }

  & li.menu + .nav-header {
    border-color: $gray-400;
  }
}

.green ul.nav-menu > li.open > button,
.green ul.nav-menu > li > button:hover,
.green ul.nav-menu > li > button:focus,
.green ul.nav-menu > li.open > a,
.green ul.nav-menu > li > a:hover,
.green ul.nav-menu > li > a:focus {
  background-color: $white;
  color: $app-primary-green;
}

.green ul.nav-menu li.menu.open > a {
  border-color: $app-primary-green;
}

.green ul.nav-menu li.menu > button:focus:before,
.green ul.nav-menu li.menu > button:hover:before,
.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu > a:hover:before {
  color: $app-primary-green;
}

.green ul.nav-menu li ul {
  background-color: $white;
}

.green ul.nav-menu li.menu > button:before,
.green ul.nav-menu li.menu > a:before {
  color: $sidebar-text-green;
}

.green ul.nav-menu li.menu.open > a:before,
.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu li > a:hover:before {
  color: $app-primary-green;
}

.green ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-green;
}

.green ul.nav-menu li.menu.open .sub-menu li > a:after {
  background-color: $app-primary-green;
}

.green ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: $sidebar-green;
  color: $app-primary-green;
}

.green ul.nav-menu li.menu .sub-menu li > a:hover,
.green ul.nav-menu li.menu .sub-menu li > a:focus,
.green ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $white;
  color: $app-primary-green;
}

/*Header top Navbar Styles*/
.green .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-green;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-green;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-green;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-green;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-green;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-green;
    }
  }
}

.green .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-green;
    }
  }
}

.green .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

//_app-module.scss
.green .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-green;
    }
  }
}

//_calendar.scss
.green .rbc-event {
  background-color: $app-primary-green;
}

.green .rbc-event.rbc-selected {
  background-color: darken($app-primary-green, 10%);
}

.green .rbc-slot-selection {
  background-color: rgba($app-primary-green, 0.7);
}

.green .rbc-toolbar button:active,
.green .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-green, 0.9);
  border-color: $app-primary-green;
}

.green .rbc-toolbar button:active:hover,
.green .rbc-toolbar button.rbc-active:hover,
.green .rbc-toolbar button:active:focus,
.green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-green, 0.9);
  border-color: $app-primary-green;
}

.green .rbc-toolbar button:focus {
  background-color: rgba($app-primary-green, 0.9);
  border-color: $app-primary-green;
}

.green .rbc-toolbar button:hover {
  background-color: rgba($app-primary-green, 0.9);
  border-color: $app-primary-green;
}

//_chat.scss
.green .chat-sidenav-title {
  color: $app-primary-green;
}

.green .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-green, 45%);
  }
}

//_dashboard.scss
.green .contact-list {
  & i {
    color: $app-primary-green;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.green .Collapsible__trigger {
  background: $app-primary-green;
}

.green .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-green;
  }
}

//_login.scss
.green .login-content .form-control {
  &:focus {
    border-color: $app-primary-green;
  }
}

//_portfolio.scss
.green .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-green;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-green;
    }
  }
}

//_card.scss
.green .profile-intro {
  & .icon {
    color: $app-primary-green;
  }
}

.green .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-green;
  }
}

//_tables.scss
.green .actions {
  color: $secondary-green;
}

.green .table-hover tbody tr:hover {
  background-color: rgba($app-primary-green, 0.075);
}

//Border Color

.green .border-primary {
  border-color: $app-primary-green !important;
}

// login page content

.green .app-logo-content {
  background-color: $app-primary-green;
}

.green .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-green;
    color: $app-primary-green;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-green;
    }
  }
}

/*Button Group Styles*/
.green .btn-group,
.green .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-green;
      border-color: $app-primary-green;
    }
  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-green;
      &:hover,
      &:focus {
        background-color: rgba($app-primary-green, 0.12);
        color: $app-primary-green;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.green .jr-fillchart-btn-close,
.green .jr-onchart .jr-badge-up,
.green .jr-task-list-item:hover .gx-text-hover {
  color: $app-primary-green;
}

.green .jr-entry-title:before {
  background-color: $app-primary-green;
}

.green .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-green;
  }
}

.green .slick-dots li.slick-active button:before {
  border-color: $app-primary-green;
}

//Nav Styles
.green .nav-pills .nav-link.active,
.green .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-green;
}

// Gradient Color Class
.green .bg-gradient-primary {
  @include gradient-directional($app-primary-green, lighten($app-primary-green, 16%), 0deg);
}
.green .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-green, 10%), lighten($app-primary-green, 16%), 70%, 100%);
}

.green .btn-group-head,
.green .btn-group--head-vertical {
  > .jr-btn {
    color: white;

    &.active {
      background-color: $app-head-green;
    }
  }
}

.btn-group-head,
.btn-group--head-vertical {

  > .jr-btn {
    background-color: transparent;
  }
}

.green .jr-btn-success {
  background-color: lighten($success, 30%);
  color: $white;
  font-weight: $font-weight-bold !important;

  &:hover,
  &:focus,
  &.active {
    background-color: $success !important;
    color: $white !important;
  }
}

.green .jr-btn-danger {
  background-color: lighten($danger, 30%);
  color: $white;
  font-weight: $font-weight-bold !important;

  &:hover,
  &:focus,
  &.active {
    background-color: $danger !important;
    color: $white !important;
  }
}
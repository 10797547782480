$danger: #f5c6cb;
$success: #c3e6cb;
$warning: #ffeeba;

.ag-theme-balham {
    --ag-cell-horizontal-padding: 3px;
    --ag-row-height: 23px;   
}

.ag-theme-balham .table-warning {
  background-color: $warning;
  --ag-row-hover-color: #fcf4dc;
}

.ag-theme-balham .table-danger {
  background-color: $danger;
  --ag-row-hover-color: #f8d9dc;
}

.ag-theme-balham .table-success {
  background-color: $success;
  --ag-row-hover-color: #d1e4d5;
}

.ag-theme-alpine {
  --ag-cell-horizontal-padding: 5px;
}

.ag-theme-alpine .table-warning {
background-color: $warning;
--ag-row-hover-color: #6b685d;
}

.ag-theme-alpine .table-danger {
background-color: $danger;
--ag-row-hover-color: #f8d9dc;
}

.ag-theme-alpine .table-success {
background-color: $success;
--ag-row-hover-color: #d1e4d5;
}


$body_padding: 250px;

#fixed-add-button {
  left: calc(100vw - 120px);
  top: calc(100vh - 120px);    

  @media screen and (max-width: 575px) {
    top: calc(100vh - 150px);    
    left: calc(100vw - 80px);
  }
 
  position:fixed;
  z-index: 1;
}

.add-button {
  left: calc(100vw - 100px);
  top: calc(20px);    

  // @media screen and (max-width: 575px) {
  //   top: calc(100vh - 150px);    
  //   left: calc(100vw - 80px);
  // }
 
  position:fixed;
  z-index: 1;
}

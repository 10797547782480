/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  margin-left: 30px;
  margin-right: 30px;

  //[hlo]
  @media screen and (max-width: 1000px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media screen and (max-width: 800px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media screen and (min-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}


.ag-theme-balham .bg-danger {
  background-color: lighten($danger, 35%) !important;
}

.ag-theme-balham .bg-warning {
  background-color: lighten($warning, 40%) !important;
}

.ag-theme-balham .bg-success {
  background-color: lighten($success, 40%) !important;
}

.ag-theme-balham .bg-grey {
  background-color: #f5f7f7 !important;
  color: darken(#f5f7f7, 20%);
}

.ag-theme-balham .state-red {
  background-color: $danger !important;
  color: white;
}

.ag-theme-balham .state-green {
  background-color: $success !important;
  color: white;
}

.ag-theme-balham .state-yellow {
  background-color: $warning !important;
  color: white;
}

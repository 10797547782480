
.header-menu {
  line-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & .title {
    color: $white;
    text-transform: capitalize;
    font-weight: 600;
  }

  & .subtitle {
    font-weight: 300;
    color: $white;
  }
}


.info-panel {
  margin: 20px;
  border: 2px dashed #CDD2E3;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 30px 20px 30px 20px;
  color: #8F91A4;
  font-size: 20px;
  line-height: 18px;
  white-space: normal;
  text-align: center;
}
@import "bootstrap";

.side-nav {

  & .user-profile {
    @extend .d-flex;
  }

  & .user-profile-left {
    padding-right: 20px !important;
    padding-left: 0px !important;
    @extend .flex-row;
  }

  & .user-profile-right {
    padding-left: 20px !important;
    padding-right: 0px !important;
    @extend .flex-row-reverse;
  }
}